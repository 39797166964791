<template>
  <div class="mb-4">
    <div class="d-flex">
      <div class="font-weight-bold text-h5 mr-2">
        {{bannerName}}
      </div>

      <div v-if="!disabled">
        <v-tooltip top>
          <template  v-slot:activator="{on}">
            <v-btn icon v-on="on" @click="openEditor">
              <v-icon>fa fa-edit</v-icon>
            </v-btn>
          </template>

          <div>
            {{'banner.action.edit'| t}}
          </div>
        </v-tooltip>

        <v-tooltip top>
          <template  v-slot:activator="{on}">
            <v-btn color="red lighten-1" icon v-on="on" @click="removeBannerConfirm">
              <v-icon>fa fa-trash</v-icon>
            </v-btn>
          </template>

          <div>
            {{'action.remove'| t}}
          </div>
        </v-tooltip>
      </div>
    </div>

    <div class="font-weight-light" v-if="attributes">
      {{attributes}}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    formDataItem: {
      type: Object,
    },
    banner: {
      type: Object,
      default: null,
    },
    updateBannerRequest: {
      type: Function,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    openEditor() {
      this.$apopup.base({
        width: '600px',
        title: this.$t('banner.action.edit'),
        defaultValue: this.$eagleLodash.cloneDeep(this.banner),
        bodySlot: () => import('./bannerModelEditorPopup.vue'),
        formDataItem: this.formDataItem,
        disabledApply: data => {
          if(!data) return false
          return this.$eagleLodash.isEqual(data, this.banner)
        },
        applyCallback: async (data) => {
          this.$emit('updateBanner', data)
          await this.$nextTick()
          this.updateBannerRequest(false)
        },
      })
    },
    removeBannerConfirm() {
      this.$apopup.base({
        title: this.$t('remove.confirm'),
        applyCallback: this.removeBanner,
      })
    },
    async removeBanner() {
      try {
        await this.$api.collection.bannerApi.delete(this.banner.id)
      } catch (error) {
        console.error(error)
      } finally {
        this.$emit('removeBanner')
      }
    },
  },
  computed: {
    attributes() {
      const attributes = []
      if(this.banner.fullscreen == true) {
        attributes.push(this.$t('banner.data.fullscreen'))
      }
      if(this.banner.autoplay == true) {
        const autoplay = this.$t('banner.data.autoplay')
        const ms = this.$t('unit.ms')
        attributes.push(`${autoplay}(${this.banner.interval}${ms})`)
      }
      return this.$eagleLodash.replace(attributes.toString(), ',', ' / ')
    },
    bannerName() {
      if(!this.banner) {
        return this.$t('banner.null')
      }
      return this.banner.name || this.$t('banner.undefined')
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
